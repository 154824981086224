.logo{
    width: 200px;
    height: 200px;
    padding-top: 50px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    img{
        width: 100%;
        height: 100%;
    }
}
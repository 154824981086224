.page-structure-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0;
  margin: 0;

  /* background-color: #f5f5f5; */
}

.page-structure-sidebar {
  height: 100%;
  background-color: #f5f5f5;
  padding: 0;
  margin: 0;
  border-right: 1px solid #e0e0e0;
}

.page-structure-content {
  height: 100%;
  /* padding: 0;
    padding: 0 25px; */
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
}

.page-structure-content-inner {
  padding: 50px 25px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-wrapper {
  top: 0;
  z-index: 1002;
  background-color: var(--background-color);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid var(--background-color-darker);
}


@media (max-width: 768px) {
  .page-structure-wrapper {
    flex-direction: column;
  }

  .page-structure-sidebar {
    display: none;
  }

  .page-structure-content {
    width: 100%;
    height: auto;
  }

  .page-structure-content-inner {
    padding: 0px;
    margin: 10px;
  }
}
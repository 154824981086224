.action{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
}

.action-title{
    font-weight: 800;
    color: var(--primary-color);
    border-right: 1px solid var(--primary-color);
    padding-right: 10px;

}

.action-icons{
    display: flex;
    flex-direction: row;
    gap: 5px;
    transition: 0.3s;

}

.action-icons :hover{
    cursor: pointer;
    color: var(--primary-color);
    transition: 0.3s;
}

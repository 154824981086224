.card {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b5c2cf;
}

.seperator {
  width: 100%;
  height: 1px;
  background-color: #b5c2cf;
  margin: 0;
  margin: 10px 0;
}

.card-header {
  padding: 0;
  margin: 0;
  justify-self: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  text-align: start;
  width: 80%;
}

.card-header h1 {
  font-size: 1.6rem;
  font-weight: 600;
}

.card-header h2 {
  font-size: 1.4rem;
  font-weight: 500;
}

.card-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.card-main {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.card-main h2 {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.card-main h3 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.card-main h4 {
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0;
  margin: 10px 0 0 5px;
}

.card-main p {
  font-size: 1rem;
  font-weight: 400;
  padding: 10px;
  margin: 10px 0 0px 10px;

  background-color: #45505a;
}

.card-side {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 0 10px;
  margin: 0;
}

.card-main-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.card-main-attachments {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.card-main-attachments-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
  /* margin: 5px; */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #45505a;
}

.card-main-attachments-images img {
  width: 300px;
  cursor: pointer;
  margin: 10px;
  border-radius: 5px;
}

.card-main-comments {
  width: 100%;
}
.card-main-comments-section {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.comment-event {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 0 0px 10px;
  background-color: #45505a;
}

.comment-text {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 0 0px 10px;
  background-color: #45505a;
}

.comment-event-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.comment-text-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
}

.comment-date {
  font-size: 0.8rem;
  font-weight: 400;
  color: #b5c2cf;
  padding-top: 20px;
  align-self: flex-end;
}

.comment-attachments {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
  width: 100%;
  
  gap: 10px;
}

.comment-attachments-images{
  display: flex;
  width: 85%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
  overflow-x: auto;
  gap: 10px;
}

.comment-attachments-images img {
  max-height: 100px;
  cursor: pointer;
  margin: 10px;
  border-radius: 5px;
}

.card-main-add-comment {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
}

.card-main-add-comment textarea {
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 10px;
  background-color: #45505a;
  color: #b5c2cf;
  border: 0;
  border-radius: 5px;
}


@media (max-width: 768px) {

  .card-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-main {
    width: 100%;
    align-items: center;
  }

  .card-side {
    width: 100%;
    align-items: center;
    text-align: center  ;
  }


}


.chatrooms {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid #ccc;
}
.chatrooms__list {
  /* height: 100%; */

  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 100px;

}

.chatrooms__listItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  gap: 10px;
  background-color: var(--secondary-color);

}

.chatrooms__list .load-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--tertiary-color);
  color:#fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chatrooms__list .load-more:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.chatrooms__search form{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;

}

@media (max-width: 768px) {
  .chatrooms h1 {
    display: none;
  }
  .chatrooms {
    width: 100%;
  }
}

.authForms-wrapper{
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 50px;
}

.authForms{
height: 100%;
width: 100%;
}
.header__right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 90%;
   
   
}
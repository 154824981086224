.add-ticket-button__button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    background-color: var(--primary-color);
    color: var(--primary-text-color);

    border :2px solid var(--primary-text-color);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
    text-decoration: none;
    padding: 10px 20px;
    margin: 10px 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.308);
}

.add-ticket-button__button:hover{
    background-color: var(--secondary-color);
    scale: 0.95;

}

.notifications {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

}

.notifications-panel {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.308);
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  /* width: 350px; */
  height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100000;
  overflow-x: hidden;

}

.notification {
  padding: 5px;
  background-color: var(--secondary-color);
  /* border-bottom: 1px solid var(--primary-text-color); */
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 290px;

}


.notifications-panel__content .load-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--tertiary-color);
  color:#fff;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.notifications-panel__content .load-more:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {


  .notifications-panel {
    width: 90vw;
    position:fixed;
    top: 100px;
    right: 10px;
  }

  .notification {
    width: 80vw;
  }
}
.chatroom-wrapper {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.chatroom {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

}
.message-bubble__time{
font-size: 12px;
width: 100%;
text-align: right;
padding: 0 10px;
}

.chatroom-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.chatroom-header__userInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.chatroom-header__name {
  margin: 0;
  padding: 0;
}

.chatroom-header__status {
  margin: 0;
  padding: 0;
}
.chatroom-header__status h3 {
  margin: 0;
  padding: 0;
}

.chatroom-messages-container {
  display: flex;
  flex-direction: column-reverse;
  align-self: stretch;
  justify-self: stretch;
  width: 90%;
  align-self: center;
  overflow: hidden;
  padding: 10px;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
}

.chatroom-input {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chatroom-input__message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.message-bubble-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.message-bubble-wrapper.sent {
  justify-content: flex-end;
}
.message-bubble-wrapper.sent .message-bubble__sender {
  display: none;
}
.message-bubble {
  display: flex;
  flex-direction: column;

  max-width: 75%;
  border-radius: 10px;
  text-wrap: break-word;
  padding: 10px;
  overflow-wrap: break-word; /* Ensures the text breaks to prevent overflow */
  word-wrap: break-word; /* Deprecated but used for older browsers */
  word-break: break-word; /* Ensures proper word breaking */
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.message-bubble.sent {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-self: flex-end;
  justify-self: flex-end;

  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  border-radius: 10px 10px 0px 10px;
}

.message-bubble.received {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-self: flex-end;
  justify-self: flex-end;

  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 0px 10px 10px 10px;
  background-color: var(--primary-color);
}

.message-bubble__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.message-bubble__images {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  /* height: 100px; */
}

.message-bubble__images img {
  width: 200px;
  height: 200px;
  cursor: pointer;
  object-fit: cover;
}

.message-bubble__attachments{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

}

.message-bubble__attachment {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  width: 100%;
  height: 100%;
  color: white;
}

.message-bubble__attachment a {
  color: white;
}

.chatroom-messages-container .load-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--tertiary-color);
  color:#fff;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chatroom-messages-container .load-more:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.chatroom-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  color:#fff;
}

.chatroom-info__users{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chatroom-info__users-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.chatroom-info__user{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    
}
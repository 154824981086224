.pop-up-window-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pop-up-window {
  transition: all 0.3s ease-in-out;
  animation: pop-up-window-appear 0.3s ease-in-out;
  width: 80%;
  max-height: 80%;
  overflow-y: scroll;
  padding: 50px 10px 50px 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

}

.pop-up-window-header {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  /*border-bottom: 1px solid #c6c6c6;*/
}

.pop-up-window-title {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  padding-left: 50px;
}

.pop-up-window-close {
  width: 30px;
  color: #6a6a6a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pop-up-window-body {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

@keyframes pop-up-window-appear {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

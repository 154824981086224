General Styles for the Kanban Board
.main-container {
    display: flex;
    align-items: start;
    gap: 20px;
    padding: 20px;
    overflow-x: auto;
}

.task-container {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    width: 300px;
    min-height: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container-name-input {
    width: calc(100% - 10px); /* Adjust width to fit within container */
    margin-bottom: 10px;
    padding: 5px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: hsl(0, 13%, 79%);
}

.task {
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: grab;
}

.add-task {
    text-align: center;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-task:hover {
    background-color: #d0d0d0;
}

/* Styles for the Add Container Button */
.add-container-btn {
    background-color: #007bff; /* Blue color */
    color: #ffffff; /* White text */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin: 20px 0; /* Add some margin to the top and bottom */
}

.add-container-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.add-container-btn:focus {
    outline: none; /* Remove the outline on focus */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Add a blue glow */
}
.calendar-table-wrapper {
  width: 100%;
  max-height: 60vh; /* Maximum height for the scrollable area */
  overflow: auto; /* Enables vertical scrolling */
}

.calendar-wrapper table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: white;
}


.calendar-wrapper th,
.calendar-wrapper td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  width: 40px;
}

.calendar-wrapper thead {
  background-color: var(--secondary-color);
  color: white;
}

.booked {
  background-color: #ffcccb; /* Light red background for booked dates */
  border-left: none;
  border-right: none;
  position: relative;
}

.colliding {
  background-color: #ed4b48; /* Darker red background for colliding dates */
  border-radius: 0; /* No border-radius for colliding dates */
  position: relative;
}
.calendar-wrapper .name-column {
  width: 200px;
  position: sticky;
  left: 0;
  z-index: 1;
}

.calendar-wrapper th.name-column {
  background-color: var(--secondary-color);
  color: white;
}

.calendar-wrapper td.name-column {
  background-color: white;
  color: black;
}

.calendar-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-bottom: 20px;
  align-items: center;
  gap: 20px;
}

.calendar-buttons-days{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.calendar-buttons-search {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 10px;
}

.calendar-button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  width: 300px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.calendar-table-wrapper {
  width: 100%;
  max-height: 60vh; /* Adjust this value based on your needs */

}

.calendar-wrapper thead  {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--secondary-color); /* Make sure the background is not transparent */
}

@media (max-width: 768px) {
  .calendar-buttons-search{
    flex-direction: column;
  }

  .calendar-buttons-days{
    flex-direction: column;
  }
}

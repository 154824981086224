.properties-wrapper{
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0;
    margin: 0;
    /* background-color: #f5f5f5; */
}

.properties-sidebar{

    height: 100%;
    background-color: #f5f5f5;
    padding: 0;
    margin: 0;
    border-right: 1px solid #e0e0e0;
}

.properties-content{

    height: 100%;
    padding: 0;
    margin: 0;
    margin-left:25px
}

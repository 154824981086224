.ticket-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ticket-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.ticket-form-header{
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: left;
  /* align-self: flex-start; */
}

.loading-indicator-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-indicator {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 20px;
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid var(--primary-color); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
  border: 1px solid red;
  background-color: #f8d7da;
  padding: 5px;
  font-size: 15px;
  font-weight: 600;
  margin: 0.5rem 0;
}
.success-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: green;
  border: 1px solid green;
  background-color: #d4edda;
  padding: 5px;
  font-size: 15px;
  font-weight: 600;
  margin: 0.5rem 0;
}
.form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.form-field {
  width: 100%;
}

.form-field-label {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.form-field-input {
  width: 100%;
  height: 2.4rem;
  border: 1px solid hsl(0, 0%, 70%);
  border-radius: 4px;
  font-weight: 600;
  margin: 0;
  padding: 0rem;
  font-weight: normal;
  box-sizing: border-box;
  padding: 2px 8px;
  font-size: 1rem;
  

}

textarea {
  resize: vertical;
  min-height: 100px;
}
.form-field-input:focus {
  outline: none;
  border: 2px solid var(--secondary-color);
  transition: all 0.3s ease;
}

.form-field + .form-field {
  margin-left: 1rem; /* Adjust the value as needed */
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  width: 100%;
  border: 1px dashed #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 6px 0px;
  cursor: pointer;
}

.submit-button {
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: var(--secondary-color);
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}


@media (max-width: 768px) {
  .ticket-form {
    width: 100%;
  }
  .form-group {
    flex-direction: column;
  }
  .form-field + .form-field {
    margin-left: 0; /* Adjust the value as needed */
  }
}